body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Goutham" !important;
}

@font-face {
  font-family: "Goutham";
  font-style: normal;
  font-weight: normal;
  src: local("Goutham"), url("./Assets/Fonts/Gotham-Light.otf") format("woff");
}

@font-face {
  font-family: "NothanSans";
  font-style: normal;
  font-weight: normal;
  src: local("NothanSans"),
    url("./Assets/Fonts/NotherSans-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: normal;
  src: local("Orbitron"),
    url("./Assets/Fonts/Orbitron-Regular.ttf") format("woff");
}

.customDot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background-color: lightgray !important;
  transition: background-color 0.3s ease !important;
  border: 1px solid lightgray !important;
  outline: none !important;
  cursor: pointer !important;
}

.disablesInput {
  display: none;
}

.row-custom-style {
  row-gap: 20px;
}

.slick-dots {
  bottom: -70px !important;
}

.slick-list {
  width: 90% !important;
  margin: auto !important;
}

::-webkit-scrollbar {
  /* width: 0px; */
  display: none !important;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #969696 !important;
  border-radius: 10px;
}

.slick-prev,
.slick-next {
  top: 25% !important;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-justify {
  text-align: justify !important;
}

.slick-dots li {
  width: unset !important;
}

.slick-active {
  width: unset !important;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: unset !important;
}

.slick-active .customDot {
  background-color: var(--black-background) !important;
  width: 25px !important;
  border-radius: 25px !important;
}

@media screen and (max-width: 1440px) {
  .slick-list {
    width: 95% !important;
  }

  .slick-prev,
  .slick-next {
    top: 45% !important;
  }
}

@media screen and (max-width: 1024px) {
  .slick-prev,
  .slick-next {
    top: 25% !important;
  }

  .slick-dots {
    bottom: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  main .slick-dots {
    bottom: 0px !important;
  }

  .slick-dots {
    bottom: 0px !important;
  }
}

@media screen and (max-width: 450px) {
  .slick-dots {
    bottom: 0px !important;
  }
}

:root {
  --white-background: #ffffff;
  --white-background-5: #ffffffcc;
  --black-background: #000000;
  --main-background1: rgba(4, 1, 59, 1);
  --main-background2: rgba(0, 70, 135, 1);
  --lightgray-background: lightgrey;
  --lightgreen-btn-background: #59e46f;
  --bikelightgray-background: #e0e0e066;
  --main-page-background: #f9f9f9;
  --description-light-text: #000000d9;
}
